import React, { useState, useEffect } from "react";
import "./date.css";

const DateComponent = () => {
  const [text, setText] = useState(
    "საიტზე მიმდინარეობს მუშაობა || დაგვიკავშირდით +995 577 554028 ||"
  );

  useEffect(() => {
    // Get the current date
    const d = new Date();

    // // Format the date as needed (e.g., "YYYY-MM-DD HH:MM:SS")
    // const formattedDate = d.toLocaleString("ka-GE", {
    //   year: "numeric",
    //   month: "numeric",
    //   day: "numeric",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   second: "2-digit",
    // });

    // Update the text with the date
    setText((prevText) => prevText + " " + d);
  }, []);

  return (
    <div className="ticker_Body">
    <div className="ticker-container">
      <div className="ticker">
        <p className="ticker-text">{text}</p>
      </div>
    </div>
    </div>
  );
};

export default DateComponent;
