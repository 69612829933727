import React from "react";
import './App.css';
import Home from "./components/pages/home/Home";
import Price from "./components/pages/pricing/Price";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contacts from "./components/pages/contacts/Contacts";
import About from "./components/pages/about/About";
function App() {
  return (
    
    <div className="App">
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Price />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/about" element={<About />} />
    </Routes>
  </Router>
  </div>
  );
}

export default App;
