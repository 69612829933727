import React, { Component } from "react";
import "./body.css";
import PdfViewer from "../../pdfOpener/PdfViewer";
class Body extends Component {
  render() {
    const pdfUrl_1 = process.env.PUBLIC_URL + '/pdf_files/1.pdf';
    const pdfUrl_2 = process.env.PUBLIC_URL + '/pdf_files/2.pdf';
    const pdfUrl_3 = process.env.PUBLIC_URL + '/pdf_files/3.pdf';
    const pdfUrl_4 = process.env.PUBLIC_URL + '/pdf_files/4.pdf';
    const pdfUrl_5 = process.env.PUBLIC_URL + '/pdf_files/5.pdf';
    return (
        <main>
        <section class="content">
            <h1 class="H1" id="h1"> ბიზნეს უსაფრთხოების აუთსორსინგი</h1>
            <p1 id="p1">ჩვენი შემოთავაზება მოიცავს, როგორც შიდა უსაფრთხოების
სამსახურის შექმნის სრულ პაკეტს, ასევე უკვე არსებულ
კანონთან და თანამედროვე მოთხოვნებთან შესაბამისობაში
მოყვანას, რეკომენდაციებს კადრების შერჩევაში, მათ
მომზადება/გადამზადებასა და სათანადოდ მართვაში.</p1>
<div class="Logo">
        <div class="Logo_box">
          <a href="index.html"><div class="pentagon bc_g1">
          <div class="star"></div>
          </div>
        </a>
          <div class="P_underbox bc_b1"></div>
          </div>
          <div className="bsc_logo">BSC</div>
          </div>
        </section>

       <div class="mP1">
        <div class="md sec_form1" id="md1">
            <h2 class="mh">დიაგნოსტირება და დიზაინი</h2>
            <p2 class="mp">კომპანიაში ფიზიკური დაცვის მიმართულებით არსებული
მდგომარეობის შესწავლის, ხარვეზების გამოვლენის და შემდგომი
ნაბიჯების სწორად შემუშავების მიზნით, აუცილებელია
თავდაპირველი დიაგნოსტირება და შეფასება. ეს პროცესი მოიცავს ...
            <PdfViewer pdfUrl={pdfUrl_1} />
            </p2>            
        </div>
        <div class="md sec_form2" id="md2">
            <h3 class="mh">პროცესების და პროცედურების განვითარება</h3>
            <p3 class="mp">მას შემდეგ, რაც მოხდება ფიზიკური დაცვის სისტემის
დასანერგად სამუშაო არეალის განსაზღვრა, შეიქმნება ორგანიზაციის
ფიზიკური უსაფრთხოების პოლიტიკა, BSCF-ის სპეციალისტები
შეიმუშავებენ პოლიტიკის განვითარებისთვის საჭირო სისტემის
დეტალურ დიზაინს, განავითარებენ ...
<PdfViewer pdfUrl={pdfUrl_2} /></p3>
        </div>
        <div class="md sec_form3" id="md3">
            <h4 class="mh">ჩართვა და სწავლება</h4>
            <p4 class="mp">
            კომპანიის ფიზიკური დაცვის პოლიტიკის განსაზღვრის და
პროცედურების დამტკიცების შემდგომ, შესაძლებელი გახდება
სასწავლო სატრენინგო მოდულების განვითარება და
განხორციელება.
წინასწარი შეფასებით ტრენინგები და კონსულტაციები ჩატარდება
შემდეგ თემებზე... <PdfViewer pdfUrl={pdfUrl_3} /></p4>
        </div>
        <div class="md sec_form4" id="md4">
            <h5 class="mh">დანერგვის მხარდაჭერა</h5>
            <p5 class="mp">ამოცანა:
პროცედურების დანერგვის მიზნით საჭიროა მხარდაჭერის გაწევა
კონსულტაციებითა და ქოუჩინგით.
სისტემის მდგრადი და ეფექტური მუშაობის უზრუნველსაყოფად
სისტემის დანერგვისა და მხარდაჭერის მომსახურება მოიცავს
ქვემოთ ჩამოთვლილ სერვისს... <PdfViewer pdfUrl={pdfUrl_4} /></p5>
        </div>
        <div class="md sec_form5" id="md5">
            <h6 class="mh">მდგრადობის უზრუნველყოფა</h6>
            <p6 class="mp">დანერგილი ფიზიკური დაცვის სისტემის მდგრადობის
შენარჩუნების მიზნით მოხდება სისტემის საბოლოო დამუშავება
და შეფასება:

შედეგების გაანალიზება და საბოლოო დახვეწა, რაც
უზრუნველყოფს სისტემის ეფექტურობას და მდგრადობას;
საკორექტირო ღონისძიებების შემუშავება...
<PdfViewer pdfUrl={pdfUrl_5} /></p6>
        </div>
       </div>
    </main>
    );
  }
}

export default Body;
