import React from 'react';

const PdfViewer = ({ pdfUrl }) => {
  const openPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div>
      <button onClick={openPdf}>Open PDF</button>
    </div>
  );
};

export default PdfViewer;
