import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons CSS
const Footer = () => {
    const [inputValue, setInputValue] = useState('');
  return(
   
    <footer class="text-white text-center text-lg-start" style={{ backgroundColor: '#23242a' }}>
      <div class="container p-4">
        <div class="row mt-4">
          <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
            <h5 class="text-uppercase mb-4">ჩვენ შესახებ</h5>
  
            <p>
             ბიზნეს უსაფრხოების საკონსულტაციო ფირმა,
            </p>
  
            <p>
              ჩვენ ვზრუნავთ თქვენს უსაფრხოებაზე
            </p>
  
            <div class="mt-4">
              <a type="button" class="btn btn-floating btn-warning btn-lg"><i class="fab fa-facebook-f"></i></a>
              <a type="button" class="btn btn-floating btn-warning btn-lg"><i class="fab fa-dribbble"></i></a>
              <a type="button" class="btn btn-floating btn-warning btn-lg"><i class="fab fa-twitter"></i></a>
              <a type="button" class="btn btn-floating btn-warning btn-lg"><i class="fab fa-google-plus-g"></i></a>
            </div>
          </div>
  
          <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase mb-4 pb-1">ძიება</h5>
  
            <div class="form-outline form-white mb-4">
            <input type="text" id="formControlLg" className="form-control form-control-lg" value={inputValue}
  onChange={(e) => setInputValue(e.target.value)}
/>

              <label class="form-label" for="formControlLg" style={{marginLeft: '0px'}}></label>
            <div class="form-notch"><div class="form-notch-leading" style={{width: '9px'}}></div><div class="form-notch-middle" style={{width: '48.8px'}}></div><div class="form-notch-trailing"></div></div></div>
  
            <ul class="fa-ul" style={{ marginLeft: '1.65em'}}>
              <li class="mb-3">
                <span class="fa-li"><i class="fas fa-home"></i></span><span class="ms-2">თბილისი, საქართველო, 0107, GE</span>
              </li>
              <li class="mb-3">
                <span class="fa-li"><i class="fas fa-envelope"></i></span><span class="ms-2">bscf.georgia@gmail.com</span>
              </li>
              <li class="mb-3">
                <span class="fa-li"><i class="fas fa-phone"></i></span><span class="ms-2">+995 577 554028</span>
              </li>
              <li class="mb-3">
                <span class="fa-li"><i class="fas fa-print"></i></span><span class="ms-2">+995 577 554028</span>
              </li>
            </ul>
          </div>
  
          <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase mb-4">სამუშაო საათები</h5>
  
            <table class="table text-center text-white">
              <tbody class="font-weight-normal">
                <tr>
                  <td>ორ - სამ:</td>
                  <td>00.00 - 24.00</td>
                </tr>
                <tr>
                  <td>პარ - შაბ:</td>
                  <td>00.00 - 24.00</td>
                </tr>
                <tr>
                  <td>კვირა:</td>
                  <td>00.00 - 24.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      
      <div class="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
        2024, Georgia
      
      </div>
      
    </footer>
   
    
  );
};
export default Footer;