import React from "react";
import "./small.css";
const Small = () => {
  return (
    <div className="bsHeader">
    <h1 className="mid_Header">
    Business Security Consultation
    </h1>
    </div>
  );
};
export default Small;