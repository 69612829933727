import React from "react";
import { Link } from 'react-router-dom';
import "./header.css";
import Home from "../../../pages/home/Home";
import Price from "../../../pages/pricing/Price";
const Header = () => {
    return (
        <ul class="topnav">
        <li><Link class="active" to='/'>მთავარი</Link></li>
        <li><Link to="/pricing">ფასები</Link></li>
        <li><Link to="/contacts">ბლოგები</Link></li>
        <li class="/about"><Link to="/about">სერვისები</Link></li>
      </ul>
     
    );
  };
  export default Header;
  