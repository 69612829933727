import React, { useState } from 'react';
import './nameForm.css';

const NameForm = () => {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !mail) {
      alert('Name and Email are required.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('mail', mail);

    try {
      const response = await fetch('/api/collect-names', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Name collected successfully!');
        setName(''); // Clear the input field after submission
        setMail(''); // Clear the input field after submission
      } else {
        alert('Error collecting name.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className='form_mail_box_holder'>
      <h2 className='form_mail_header'>მოგვწერეთ თქვენი ბიზნესის შესახებ</h2>
      <form className='form_mail_box' encType="multipart/form-data" onSubmit={handleSubmit}>
        <label className='form_mail_label_1'>
          name:
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label className='form_mail_label_2'>
          Inquiry:
          <input className='inquiry_input'
            type="text"
            name="mail"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </label>
        <button type="submit" className='submit_button_1'>
          Submit
        </button>
      </form>
    </div>
  );
};

export default NameForm;
