import React from "react";
import Footer from "../../pageComponents/homeComponents/footer/Footer";
import Header from "../../pageComponents/homeComponents/header/Header";
import DateComponent from "../../pageComponents/date/DateComponent";
import "./about.css";
const About = () => {
  return (
    <div className="sv_Services">
      <DateComponent />
        <Header/>
        <div className="sv_con_holder_1">
          <h1 className="sv_h2">მთელი ქვეყნის მასშტაბით</h1>
          <p1 className="sv_p2">გთავაზობთ ბიზნეს უსაფრთხოების აუთსორსს, თქვენი ბიზნესის უსაფრთხოების უზრუნველსაყოფად.</p1>
        </div>
        <div className="sv_con_holder_2">
          <h2 id="sv_h2">ჩვენი სერვისი მოიცავს</h2>
          <div className="sv_sec_1">
            <ol className="sv_table">
<li className="sv_item"> საწარმოს უსაფრთხოება</li>
<li className="sv_item"> სამრეწველო საწარმოს უსაფრთხოება</li>
<li className="sv_item"> სამეცნიერო-კვლევითი დაწესებულების უსაფრთხოება</li>
<li className="sv_item">კავშირგაბმულობის ობიექტის უსაფრთხოება</li>
<li className="sv_item">ოფისის უსაფრთხოება</li>
<li className="sv_item">საცხოვრებელი/ საგარაკო სახლის, უსაფრთხოება</li>
<li className="sv_item"> გასართობი ობიექტის უსაფრთხოება</li>
<li className="sv_item">საქმიანი შეხვედრის, კონფერენციის უსაფრთხოება</li>
<li className="sv_item">კვების ობიექტის უსაფრთხოება</li>
<li className="sv_item">ტვირთის დაცვა ტრანსპორტირების დროს</li>
<li className="sv_item">ბანკის უსაფრთხოება</li>
<li className="sv_item">საავტომობილო ვაგზლის უსაფრთხოება</li>
<li className="sv_item">სარკინიგზო ვაგზლის უსაფრთხოება</li>
<li className="sv_item">საჰაერო პორტის უსაფრთხოება</li>
<li className="sv_item">საზღვაო პორტის უსაფრთხოება</li>
<li className="sv_item">მილსადენის უსაფრთხოება</li>
<li className="sv_item">სასტუმროს უსაფრთხოება</li>
<li className="sv_item">დასასვენებელი პარკის, სკვერის დაცვა</li>
<li className="sv_item">სავაჭრო ცენტრის უსაფრთხოება</li>
<li className="sv_item">სასწავლო დაწესებულების უსაფრთხოება</li>
<li className="sv_item">სამეცადინო დაწესებულების უსაფრთხოება</li>
<li className="sv_item">სპორტული მოედნებისა (სტადიონების) და სპორტული ღონისძიებების უსაფღხოება</li>
<li className="sv_item">ავტოპარკის (ავტოსადგომის) უსაფრთხოება</li>
<li className="sv_item">მშენებარე საზღვაო პორტის უსაფრთხოება</li>
<li className="sv_item">მშენებარე მილსადენის უსაფრთხოება</li>
<li className="sv_item">მშნებარე ავტობანის (საგზაო მაგისტრალის) უსაფრთხოება</li>
<li className="sv_item">ფორუმის (ყრილობის, კონფერენციის) უსაფრთხოება</li>
<li className="sv_item">კომპიუტერული და კავშირგაბმულობის სისტემების დაცვა არასანქცირებული შეღწევისგან</li>
<li className="sv_item">სიგნალიზაციები, ვიდეო მეთვალყურეობის და დაცვის სისტემები</li>
<li className="sv_item">კონფიდენციალური ინფორმაციის, კომერციული საიდუმლოების დაცვა</li>
<li className="sv_item">ბიზნესდაზვერვისა და ბიზნესკონტრდაზვერვის წარმოება დაცვის ქვეშ მყოფი საწარმოს პოზიციებიდან</li>
<li className="sv_item">ბიზნესმენის, მისი ოჟახის წევრის, უცხოელი სტუმრის პირადი დაცვის ორგანიზაცია</li>
<li className="sv_item">უსაფრთხოების სამსახურის თანამშრომლების ფსიქოლოგიური მომზადება-თვითრეგულაცია და კონტროლი ექსტრემალურ სიტუაციებში</li>
            </ol>
          </div>
        </div>
        
        <Footer />
    </div>
  );
}
export default About;