import React from "react";
import Footer from "../../pageComponents/homeComponents/footer/Footer";
import Header from "../../pageComponents/homeComponents/header/Header";
import NameForm from "../../pageComponents/forms/NameForm";
import DateComponent from "../../pageComponents/date/DateComponent";
import "./price.css";
const Price = () => {
  return (
    <div className="price">
      
      <div className="animated_div_holder">
      <div class="animated_div">BSC</div>
      <div className="H1"><h1>Business Security Consultation</h1></div>
      </div>
    <DateComponent />
      <Header />
    
      <div>
      <table className="pricing">
  <tr>
    <th>კომპანია</th>
    <th>სერვისები</th>
    <th>ფასები</th>
  </tr>
  <tr>
    <td>Small</td>
    <td>full service</td>
    <td>შეთანხმებით</td>
  </tr>
  <tr>
    <td>Medium-small</td>
    <td>full-service</td>
    <td>შეთანხმებით</td>
  </tr>
  <tr>
    <td>Medium</td>
    <td>full-service</td>
    <td>შეთანხმებით</td>
  </tr>
  <tr>
    <td>Big</td>
    <td>full-service</td>
    <td>შეთანხმებით</td>
</tr>
</table>
      </div>
      <NameForm />
      {/* <Form_1 /> */}
      <Footer />  
    </div>
  );
};
export default Price;