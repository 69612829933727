import React from "react";
import Footer from "../../pageComponents/homeComponents/footer/Footer";
import Header from "../../pageComponents/homeComponents/header/Header";
import DateComponent from "../../pageComponents/date/DateComponent";
import "./contacts.css";
const Contacts = () => {
  return (
    <div className="Contacts">
      <DateComponent />
        <Header/>
        
        <div className="body">
          <div className="paragraph_box">
                        <div className="sec_1"><h1 className="H1">ვინ ვართ</h1><p4 className="P">BSCF (Business Security Consultation Firm) არის ბიზნეს უსაფრთხოების
            საკონსულტაციო კომპანია, რომელიც ახორციელებს ბიზნეს უსაფრთხოების
            აუთსორსინგს. კონსულტაციები მოიცავს, როგორც შიდა უსაფრთხოების
            სამსახურის შექმნის სრულ პაკეტს, ასევე უკვე არსებულ კანონთან და
            თანამედროვე მოთხოვნებთან შესაბამისობაში მოყვანას, რეკომენდაციებს
            უსაფრთხოების(დაცვის) კადრების შერჩევაში, მათ მომზადება/გადამზადებასა
            და სათანადოდ მართვაში. აღნიშნულს კომპანია ახორციელებს გამოცდილი,
            პროფესიონალი თანამშრომლების, სპეციალისტების და ექსპერტების მეშვეობით.</p4><div className="box_for_tags"><div className="data_tag2">Tbilisi, Georgia</div><div className="data_tag1">29/10/2023</div></div></div>
                        <div className="sec_2">
                          <div className="sec_2_1 seg"><h2 className="H">რა არის უსაფრთხოების აუთსორსინგი?</h2><p1 className="P">უსაფრთხოების სერვისების აუთსორსინგი ნიშნავს მესამე მხარის კომპანიის
            დაქირავებას თქვენი ბიზნესის უსაფრთხოების უზრუნველსაყოფად. არსებობს
            რამდენიმე სახის აუთსორსირებული უსაფრთხოების სერვისები. თქვენ უნდა
            გადაწყვიტოთ, თუ რატომ და რისთვის გჭირდებათ დაცვა. უსაფრთხოება უნდა
            იყოს ნებისმიერი ბიზნეს გეგმის ნაწილი. კრიმინალი და ვანდალიზმი იწვევს
            ბიზნესის შეფერხებას, ზიანს აყენებს თქვენი კომპანიის განვითარებას,
            თანამშრომლები თავს გრძნობენ დაუცველად. უსაფრთხოების აუთსორსინგი
            არის ეფექტური ალტერნატივა შიდა უსაფრთხოების შექმნისთვის. თქვენი
            ბიზნესი არ უნდა იყოს ადვილი სამიზნე კრიმინალისთვის.
            </p1>
            <div className="box_for_tags"><div className="data_tag2">Tbilisi, Georgia</div><div className="data_tag1">29/10/2023</div></div></div>
                          <div className="sec_2_2 seg"><h3 className="H">ცოტა რამ კერძო შიდა უსაფრთხოების სამსახურის საჭიროების

            შესახებ</h3><p2 className="P">საბაზრო ეკონომიკის პირობებში, კონკურენციული ბრძოლისას,
            განსაკუთრებით თუ ქვეყანაში არც თუ სახარბიელო, დაძაბული
            პოლიტიკური და კრიმინოგენული ვითარებაა, ბიზნესის წარმოება საკმაოდ სახიფათოა. წარმატებული
            საქმიანობისთვის თითოეული ბიზნესი მიმართავს სპეციალურ
            ღონისძიებებს საკუთარი თავის გარე და შიდა ფაქტორების
            ნეგატიური ზემოქმედებისგან დასაცავად. მაკროეკონომიკური
            უსაფრთხოება, რომელიც მნიშვნელოვანწილად სწორად
            ორგანიზებული შიდა უსაფრთხოების განხორციელებით მიიღწევა,
            ფაქტობრივად მეწარმეობის ერთ-ერთი სახეობაა, რომლის
            განხორციელების დროს გასათვალისწინებელია თანამშრომლების
            კვალიფიკაცია, შეუბღალავი რეპუტაცია და უბადლო ფიზიკური და
            ფსიქოლოგიური მომზადება.</p2><div className="box_for_tags"><div className="data_tag2">Tbilisi, Georgia</div><div className="data_tag1">29/10/2023</div></div></div>
                          <div className="sec_2_3 seg"><h4 className="H">მიიღეთ მორგებული უსაფრთხოების გეგმა</h4><p3 className="P">ბიზნესის უსაფრთხოების უზრუნველყოფა მოიცავს სპეციალურ
            ღონისძიებათა სპექტრს. თავიდან, რეალური გარემეოს მიხედვით,
            მთავარი საფრთხეების შესაბამისად, უნდა განისაზღვროს
            უსაფრთხოების მიზნები და ამოცანები. თითოეული ობიექტის
            კონკრეტული პირობების გათვალისწინებით, საჭიროა დამუშავდეს
            და დაინერგოს უსაფრთხოების უზრუნველყოფის კომპლექსური
            სისტემა, რომელიც მოიცავს ორგანიზაციულ, რეჟიმულ, ტექნიკურ,
            ინფორმაციულ და სხვა ღონისძიებებს.
            ბიზნესის უსაფრთხოების უზრუნველყოფა მოიცავს სპეციალურ
            ღონისძიებათა სპექტრს. თავიდან, რეალური გარემეოს მიხედვით,
            მთავარი საფრთხეების შესაბამისად, უნდა განისაზღვროს
            უსაფრთხოების მიზნები და ამოცანები. თითოეული ობიექტის
            კონკრეტული პირობების გათვალისწინებით საჭიროა დამუშავდეს
            და დაინერგოს უსაფრთხოების უზრუნველყოფის კომპლექსური
            სისტემა, რომელიც მოიცავს ორგანიზაციულ, რეჟიმულ, ტექნიკურ,
            ინფორმაციულ და სხვა ღონისძიებებს.</p3><div className="box_for_tags"><div className="data_tag2">Tbilisi, Georgia</div><div className="data_tag1">29/10/2023</div></div></div>
                        </div>
            <div className="sec_3"><h5 className="H">BLOG!</h5><p5 className="P">
</p5><div className="box_for_tags"><div className="data_tag2">Tbilisi, Georgia</div><div className="data_tag1">29/10/2023</div></div></div>
          </div>
        </div>
        <Footer />
    </div>
  );
}
export default Contacts;