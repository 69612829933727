import React from "react";
import Body from "../../pageComponents/homeComponents/body/Body";
import Footer from "../../pageComponents/homeComponents/footer/Footer";
import Header from "../../pageComponents/homeComponents/header/Header";
import Small from "../../pageComponents/small/Small";
import DateComponent from "../../pageComponents/date/DateComponent";
import "./home.css";
const Home = () => {
  return (
    <div className="home">
  



      <Small />
      <DateComponent />
      <Header />
      <Body />
      <Footer />  
    </div>
  );
};
export default Home;